import React from 'react'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'
import blogStyles from '../styles/index.module.scss'
import Head from '../components/Head'
import Post from '../components/Post'

export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: frontmatter___date, order: DESC }) {
      edges {
        node {
          frontmatter {
            title
            date(formatString: "MMMM D, YYYY")
            slug
            tags
          }
          timeToRead
        }
      }
    }
  }
`
const IndexPage = ({ data }) => {
  const { edges: posts } = data.allMarkdownRemark

  return (
    <Layout>
      <div className="page-section container">
        <Head title="Blog" />
        <h1 className="is-size-3">Recent Posts</h1>
        <ol className={blogStyles.posts}>
          {posts.map((post) => (
            <Post
              key={post.node.frontmatter.slug}
              timeToRead={post.node.timeToRead}
              {...post.node.frontmatter}
            />
          ))}
        </ol>
      </div>
    </Layout>
  )
}

export default IndexPage
