import React, { Component } from 'react'
import { Link } from 'gatsby'
import blogStyles from '../styles/index.module.scss'

export default class Post extends Component {
  constructor(props) {
    super(props)
    const { title, date, slug, tags, timeToRead } = props

    this.state = {
      blogUrl: `/${slug}/`,
      title,
      date,
      tags,
      timeToRead
    }
  }

  render() {
    return (
      <li className={blogStyles.post}>
        <Link to={this.state.blogUrl}>
          <h2 className="is-size-4 has-text-danger">{this.state.title}</h2>
        </Link>
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <span>{this.state.date}</span>
              <span>({this.state.timeToRead} minute read)</span>
            </div>
            <div className="level-item">
              {this.state.tags && this.state.tags.map(tag => (
                <span key={tag} className="blog-tag tag is-primary">{tag}</span>
              ))}
            </div>
          </div>
        </div>
      </li>
    )
  }
}
